<template>
  <section class="m verify-modal">
    <button class="m__close verify-modal__close" @click="closeModal">
      <IconComponent name="close-square" />
    </button>
    <div class="m__content verify-modal__content">
      <h4 class="verify-modal__title">Подтвердите новую почту</h4>
      <span class="verify-modal__subtitle">
        На новую почту отправлена ссылка для подтверждения. Проверьте свой почтовый ящик
      </span>
    </div>
  </section>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "VerifyModal",
  components: { IconComponent },
  methods: {
    closeModal() {
      this.$store.state._modals = [];
    },
  },
};
</script>

<style lang="stylus">
.verify-modal {

  &__content {
    display flex
    flex-direction column
    gap 20px
    text-align center
    +below(650px) {
      padding 20px 15px
    }
  }

  &__title {
    font-size 1.5em
    font-weight 500
    margin-bottom 0
    +below(650px){
      font-size: 1.375em;
    }
  }

  &__subtitle {
    font-size 1.125em
    +below(650px){
      font-size: 0.875em
    }
  }
}
</style>
