<template>
  <main class="profile-page">
    <div class="profile-page__wrapper">
      <ul class="profile-page__tabs">
        <li
          v-for="(item, i) in tabs"
          :key="i"
          :class="{ 'profile-page__tab': activeTab === i }"
          @click="activeTab = i"
        >
          <span>{{ item.title }}</span>
        </li>
      </ul>
      <component :is="tabs[activeTab].component" />
    </div>
  </main>
</template>

<script>
// import PAGE from "@/graphql/pages/profile_page.graphql";
import ProfileEditFormComponent from "./components/ProfileEditFormComponent.vue";

export default {
  name: "ProfilePage",
  components: {
    ProfileEditFormComponent,
  },
  data() {
    return {
      tabs: [
        {
          title: "Мои данные",
          component: "profile-edit-form-component",
        },
        // {
        //   title: "Мои курсы",
        //   component: "",
        // },
        // {
        //   title: "История олимпиад",
        //   component: "history-olympiads-component",
        // },
      ],
      activeTab: 0,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  metaInfo() {
    return {
      title: "АИСТ | Профиль пользователя",
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: "",
        },
        {
          vmid: "description",
          name: "description",
          content: "АИСТ | Профиль пользователя",
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: "АИСТ | Профиль пользователя",
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: "АИСТ | Профиль пользователя",
        },
      ],
    };
  },
};
</script>

<style lang="stylus">
.profile-page{
  background var(--white)
  padding-top: 110px

  &__wrapper{
    display flex
    width 100%;
    max-width: 960px;
    margin: 0 auto
    padding 100px 0

    +below(1060px) {
      padding 100px 30px
      //margin-top 130px
      flex-direction column
    }
  }

  &__tabs {
    width 100%
    max-width 250px
    position relative
    z-index 10

    li {
      cursor pointer
      padding 20px
      margin-right: -2px;

      span {
        font-size 1.15em
        font-weight 500
      }
    }
    +below(1060px) {
      display flex
      width 100%
      max-width 100%
      justify-content space-between
      align-items center
      border-right none

      li {
        width 100%
        text-align center
        margin-right 0
        margin-bottom: -2px;
      }
    }
    +below(680px) {
      display flex
      width 100%
      flex-direction column
      max-width 100%
      justify-content space-between
      align-items center
      border-right none

      li {
        width 100%
        text-align center
        margin-right 0
        margin-bottom: 0;

      }
    }
  }

  &__tab {
    border-right 2px solid var(--pink)
    span {
      color var(--pink)
    }

    +below(1060px) {
      border-right none
    }

    +below(680px) {
      border-right 2px solid var(--pink)
      border-left 2px solid var(--pink)
    }
  }

  &__content {
    display flex
  }
  &__menu {
    width 100%
    max-width 220px
  }
}
</style>
